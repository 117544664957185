import { Outlet } from "react-router-dom";
import LogIn from "../AdminPages/LogIn";
import { useAuthContext } from "../../State/AuthContext";

const AdminRoutes = () => {
  const { userState } = useAuthContext();
  return userState === "admin" ? <Outlet /> : <LogIn />;
};

export default AdminRoutes;
