import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callFunctionReadEventData = httpsCallable(functions, "read_event_data");

export async function readEventData(eventId) {
  try {
    const result = await callFunctionReadEventData({ eventId: eventId });
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
}
