import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-[100vw] h-[100vh] p-12 text-darkBlue flex flex-col justify-center items-center gap-5">
      <img src="../icons/ProfilePageNinja.svg" alt="ninja" />
      <h1 className="font-bold text-7xl">
        4<span className="text-red">0</span>4
      </h1>
      <h1 className="font-medium text-2xl">
        This page wasn't found<span className="text-red">.</span> Sorry about
        that
        <span className="text-red">.</span>
      </h1>
      <div className="flex gap-5">
        <Link to="/admin">
          <button className="text-white bg-blue border-2 border-blue w-[10em] py-4 rounded-lg transition-all 500ms ">
            Admin
          </button>
        </Link>
        <Link to="/">
          <button className="w-[10em] py-4 rounded-lg border-2 border-blue transition-all 500ms ">
            Join an event
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
