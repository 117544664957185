import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callFunctionGetUserData = httpsCallable(functions, "get_user_data");

export async function getUserData(user_id) {
  try {
    const result = await callFunctionGetUserData({ user_id: user_id });
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
}
