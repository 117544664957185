// creating basic values
let today = new Date();
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
export const defaultToday = today.toISOString().split("T")[0];
export const defaultMonthAgo = oneMonthAgo.toISOString().split("T")[0];

export const convertDateToReadable = (date) => {
  let readableDate = "";
  const segments = date.split("-");
  readableDate = segments[2] + "." + segments[1];
  return readableDate;
};

export const convertDateToReadableWithYear = (date) => {
  let readableDate = "";
  const segments = date.split("-");
  readableDate = segments[2] + "." + segments[1] + "." + segments[0];
  return readableDate;
};

export const textToNumberMonth = (textMonth) => {
  let numberMonth = "";
  switch (textMonth) {
    case "Jan" || "January":
      numberMonth = "01";
      break;
    case "Feb" || "February":
      numberMonth = "02";
      break;
    case "Mar" || "March":
      numberMonth = "03";
      break;
    case "Apr" || "April":
      numberMonth = "04";
      break;
    case "May":
      numberMonth = "05";
      break;
    case "Jun" || "June":
      numberMonth = "06";
      break;
    case "Jul" || "July":
      numberMonth = "07";
      break;
    case "Aug" || "August":
      numberMonth = "08";
      break;
    case "Sep" || "September":
      numberMonth = "09";
      break;
    case "Oct" || "October":
      numberMonth = "10";
      break;
    case "Nov" || "November":
      numberMonth = "11";
      break;
    case "Dec" || "December":
      numberMonth = "12";
      break;
    default:
      console.log(
        `the month in text format: ${textMonth} was not able to match up with a number. The textmonth can be passed in as "Dec" or "December".`
      );
  }
  return numberMonth;
};

export const convertTimestampToReadableWithYear = (date) => {
  let readableDate = "";
  const segments = date.split(" ");
  readableDate =
    segments[1] + "." + textToNumberMonth(segments[2]) + "." + segments[3];
  return readableDate;
};

export const compareDates = (startdate, enddate) => {
  let start = new Date(startdate).getTime();
  let end = new Date(enddate).getTime();

  if (start < end) {
    return true;
  } else if (start > end) {
    return false;
  } else {
    return true;
  }
};
