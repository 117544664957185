import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callFunctionCreateNewEvent = httpsCallable(functions, "create_new_event");

export async function createNewEvent(eventName) {
  try {
    const result = await callFunctionCreateNewEvent({ event_name: eventName });
    console.log(result.data.event);
    return result.data.event;
  } catch (error) {
    console.log(error);
  }
}
