import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./State/AuthContext";
import EventAndTimeProvider from "./State/EventAndTimeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // Strict mode is commented out because of problems with useEffect (it ran twice), which caused problems with the authentication. Strict Mode is just for Developing. For more inforamtion please refer to: https://react.dev/reference/react/StrictMode
  /*<React.StrictMode>*/
  <AuthProvider>
    <EventAndTimeProvider>
      <App />
    </EventAndTimeProvider>
  </AuthProvider>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/*
const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js", {
        scope: "/",
      });
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

// …

registerServiceWorker();
*/
