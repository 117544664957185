import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { signInAnoymousUser } from "../Auth/userAuth";
import { useAuthContext } from "../State/AuthContext";

const EnterIDPage = () => {
  useEffect(() => {
    signInAnoymousUser();
  }, []);

  const { userState } = useAuthContext();

  const [eventID, setEventID] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [joinEventLoading, setJoinEventLoading] = useState(false);

  const handleJoinEvent = () => {
    setJoinEventLoading(true);
    if (userState === "anonymous") {
      window.location.href = `/event/${eventID}`;
    } else if (userState === "unauthenticated") {
      console.log("User is not authenticated.");
      setShowError(true);
      setErrorMessage("An authentication error ocurred.");
    } else {
      setShowError(true);
      setErrorMessage(
        "An unknown error ocurred. Please try again or refresh the page"
      );
    }
    setJoinEventLoading(false);
  };

  return (
    <div className="h-[100vh] w-[80vw] lg:max-w-90vw overflow-x-hidden mx-auto my-auto flex flex-col gap-12 justify-center items-center">
      <h1 className="text-5xl text-center font-bold">
        Welcome to Nord
        <span className="text-blue text-2xl">mood</span>
      </h1>
      <div className="rounded-lg bg-darkBlue p-10 md:w-[500px] flex flex-col gap-5">
        <h3 className="text-white font-bold text-center">
          Enter your Event ID
        </h3>
        <form
          className="flex flex-col items-center gap-5"
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            type="text"
            maxLength="8"
            placeholder="ID"
            onChange={(e) => setEventID(e.target.value.toUpperCase())}
            className="lg:text-3xl centered-input-placeholder p-2 rounded-lg uppercase"
          ></input>

          <p className="text-offWhite text-center text-xs">
            By hitting "Join event" you accept our privacy policy
          </p>
        </form>
        <button
          onClick={handleJoinEvent}
          className={`${
            joinEventLoading
              ? "bg-white text-red border-red"
              : "bg-red text-white border-white"
          } py-2 px-7 font-bold rounded-lg`}
        >
          {joinEventLoading ? "Joining..." : "Join event"}
        </button>
      </div>
      {showError && (
        <div className="bg-red text-white p-3 rounded-lg">
          <p>Couldn't join Event. Error: {errorMessage}</p>
        </div>
      )}
      <div className="flex flex-col gap-2 text-center">
        <Link to="/admin">
          <p className="self-end opacity-50">Admin Login</p>
        </Link>
        <Link to="/privacypolicy">
          <p className="self-end opacity-50">Privacy Policy</p>
        </Link>
      </div>
    </div>
  );
};

export default EnterIDPage;
