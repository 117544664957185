import { auth } from "../firebase.config"; // imports global instance of the firebase auth system
import { signInAnonymously } from "firebase/auth";

export function signInAnoymousUser() {
  if (auth.currentUser == null) {
    signInAnonymously(auth)
      .then(() => {
        console.log(
          `Signed In anoymously with the user id: ${auth.currentUser.uid}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  } else if (auth.currentUser.isAnonymous) {
    console.log(
      `Already anonymously signed in with the UID: ${auth.currentUser.uid}`
    );
  } else {
    console.log("Couldn't authenticate user.");
  }
}
