import { useMemo, useState, createContext, useContext } from "react";
import {
  defaultMonthAgo,
  defaultToday,
} from "../Admin/AdminCalculations/dateCalc";

const EventAndTimeContext = createContext();

const EventAndTimeProvider = ({ children }) => {
  const [startdate, setStartdate] = useState(defaultMonthAgo);
  const [enddate, setEnddate] = useState(defaultToday);
  const [selectedEvent, setSelectedEvent] = useState("loading...");
  const [selectedEventId, setSelectedEventId] = useState("select an ID");
  const [allEvents, setAllEvents] = useState(null);

  const contextValue = useMemo(() => {
    return {
      startdate,
      setStartdate,
      enddate,
      setEnddate,
      selectedEvent,
      setSelectedEvent,
      selectedEventId,
      setSelectedEventId,
      allEvents,
      setAllEvents,
    };
  }, [
    startdate,
    enddate,
    setEnddate,
    selectedEvent,
    setSelectedEvent,
    selectedEventId,
    setSelectedEventId,
    allEvents,
    setAllEvents,
  ]);

  return (
    <EventAndTimeContext.Provider value={contextValue}>
      {children}
    </EventAndTimeContext.Provider>
  );
};

export const useEventAndTimeContext = () => {
  // this needs to be imported in every file that wants to use the context for example the "currentEvent" variable
  return useContext(EventAndTimeContext);
};

export default EventAndTimeProvider;
