import React from "react";
import { useState, useEffect, useRef } from "react";
import { ratingToSmiley } from "../AdminCalculations/eventsCalc";
import { readVotes } from "../../ApiCalls/readVotesBasedOnTimeperiod";
import { useEventAndTimeContext } from "../../State/EventAndTimeContext";
import { useReactToPrint } from "react-to-print";
import {
  getHappinessIndex,
  getTextReviewsLength,
} from "../AdminCalculations/eventsCalc";
import Loading from "./Loading";
import {
  convertDateToReadableWithYear,
  convertTimestampToReadableWithYear,
} from "../AdminCalculations/dateCalc";

const InsightsPrint = React.forwardRef((props, ref) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { startdate, enddate, selectedEventId, selectedEvent } =
    useEventAndTimeContext();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const fetchedData = await readVotes(
          startdate,
          enddate,
          selectedEventId
        );
        setData(null);
        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error("Error in App:", error);
      }
    };
    getData();
  }, [selectedEventId, startdate, enddate]);

  const happinessIndex = getHappinessIndex(data);
  const textReviews = getTextReviewsLength(data);

  return (
    <div ref={ref} className="h-[60vh] lg:h-[80vh]  flex flex-col gap-5 p-5">
      <div className="flex justify-between items-center gap-5">
        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-sm">
            Report for the event{" "}
            <span className="text-2xl">{selectedEvent}</span>
          </h3>
          <h3 className="font-bold text-sm">
            Report from{" "}
            <span className="text-2xl">
              {convertDateToReadableWithYear(startdate)}
            </span>{" "}
            till{" "}
            <span className="text-2xl">
              {convertDateToReadableWithYear(enddate)}
            </span>
          </h3>
        </div>
        <img className="w-12" src="../icons/NRDMOD.png" />
      </div>
      <div className="grid-container gap-5">
        <div className="rounded-lg p-5 text-center flex flex-col gap-5">
          <h3 className="font-bold">App usage</h3>
          <div className="">
            <p className="text-5xl font-bold">
              {data && data.length ? data.length : "0"}
            </p>
            <p className="font-light">total votes</p>
          </div>
        </div>
        <div className="rounded-lg p-5 flex flex-col gap-5 text-center">
          <h3 className="font-bold">Happiness Index</h3>
          <div>
            <p className="text-5xl font-bold">
              <span className="text-3xl">
                {happinessIndex >= 0 ? "+" : happinessIndex === "N/A" ? "" : ""}
              </span>
              {data && data.length ? happinessIndex : 0}
            </p>
            <p className="font-light">from -5 to +5</p>
          </div>
        </div>
        <div className="rounded-lg p-5 text-center flex flex-col gap-5">
          <h3 className="font-bold">Text reviews</h3>
          <div className="">
            <p className="text-5xl font-bold">
              {data && data && data.length ? textReviews : 0}
            </p>
            <p className="font-light">total reviews</p>
          </div>
        </div>
      </div>
      <Loading text="Stats and Reviews" dependsOn={loading} />
      <div>
        {data && data.length !== 0 && (
          <h3 className="font-bold text-xl mb-5">All submitted reviews</h3>
        )}
        <div
          className={
            data && data.length !== 0
              ? "grid-container gap-10"
              : "flex flex-col w-full items-center justify-center"
          }
        >
          {data && data.length !== 0 ? (
            data.map((review, index) => (
              <div className="flex items-center fadeIn gap-5" key={index}>
                <img
                  className="w-12"
                  src={`/icons/${ratingToSmiley(review.rating)}`}
                  alt="Smiley"
                />
                <div className="flex flex-col gap-2">
                  <p>
                    {review.text ? (
                      review.text
                    ) : (
                      <span className="italic text-darkBlue opacity-40">
                        No text review
                      </span>
                    )}
                  </p>
                  <p className=" text-darkBlue opacity-60">
                    {convertTimestampToReadableWithYear(review.timestamp)}
                  </p>
                  <p className="opacity-50">{review.createdAt}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="p-5 bg-offWhite flex flex-col gap-4 w-[70%] rounded-lg text-center">
              <h3 className="font-bold text-xl">Looks empty here!</h3>
              <p>
                There are no reviews for the event{" "}
                <span className="font-bold">{selectedEvent}</span> from{" "}
                <span className="font-bold">
                  {convertDateToReadableWithYear(startdate)}
                </span>{" "}
                to{" "}
                <span className="font-bold">
                  {convertDateToReadableWithYear(enddate)}
                </span>
                . Please check whether this is the correct event and period or
                give you users the ID{" "}
                <span className="font-bold">{selectedEventId}</span> to vote.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default InsightsPrint;
