import React from "react";
import { useState, useEffect } from "react";
import { useEventAndTimeContext } from "../../State/EventAndTimeContext";
import {
  compareDates,
  convertDateToReadable,
} from "../AdminCalculations/dateCalc";

// Get Date Methods:

const TimePeriodSelector = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { startdate, setStartdate } = useEventAndTimeContext();
  const { enddate, setEnddate } = useEventAndTimeContext();
  const [isTimeframeValid, setIsTimeframeValid] = useState(true);
  const [selectedStartdate, setSelectedStartdate] = useState(startdate);
  const [selectedEnddate, setSelectedEnddate] = useState(enddate);

  useEffect(() => {
    let res = compareDates(selectedStartdate, selectedEnddate);
    setIsTimeframeValid(res);
  }, [selectedStartdate, selectedEnddate]);

  const toggleShow = () => {
    setIsOpen(!isOpen);
  };

  const choosePeriod = (startdate, enddate) => {
    setStartdate(startdate);
    setEnddate(enddate);
    setIsOpen(!isOpen);
  };

  // avoid closing the popup when clicking on its div
  const handlePopUpClick = (event) => {
    event.stopPropagation();
  };

  // close the popup with esc key
  document.onkeydown = function (e) {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <div className="flex gap-3 p-2 rounded-lg items-center relative">
      <h3 className="text-lg text-darkBlue ">Period:</h3>
      <button onClick={toggleShow}>
        <div className="bg-offWhite p-3 gap-2 rounded-lg flex">
          <p>
            {convertDateToReadable(startdate) +
              " - " +
              convertDateToReadable(enddate)}
          </p>
          <img src="/icons/ArrowDown.svg" alt="" />
        </div>
      </button>
      {isOpen && (
        <div className="bg-darkBlue backdrop -z-10" onClick={toggleShow}>
          <div
            className="p-8 bg-white rounded-lg centered-popup z-10"
            onClick={handlePopUpClick}
          >
            <div className="flex flex-col gap-8">
              <h3 className="text-2xl font-bold">
                Select a time period<span className="text-red">.</span>
              </h3>
              <div className="flex gap-8 border-offWhite border-2 p-2 rounded-lg">
                Startdate:
                <input
                  required
                  type="date"
                  id="startdate"
                  defaultValue={selectedStartdate}
                  onChange={(event) => {
                    setSelectedStartdate(event.target.value.toString());
                  }}
                />
              </div>

              <div className="flex gap-8 border-offWhite border-2 p-2 rounded-lg">
                Enddate:
                <input
                  required
                  type="date"
                  id="enddate"
                  defaultValue={selectedEnddate}
                  onChange={(event) => {
                    setSelectedEnddate(event.target.value.toString());
                  }}
                />
              </div>
              {!isTimeframeValid && (
                <div className="text-white bg-red rounded-lg px-4 py-2">
                  <p>Startdate has to be before enddate</p>
                </div>
              )}
              {isTimeframeValid && (
                <div
                  className="p-3 bg-darkBlue border-2 border-darkBlue hover:bg-white hover:text-darkBlue text-white transition-all cursor-pointer w-fit px-12 rounded-lg"
                  onClick={() => {
                    choosePeriod(selectedStartdate, selectedEnddate);
                  }}
                >
                  <p>Save</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePeriodSelector;
