import { useState, useEffect } from "react";
import EventDetailView from "./EventDetailView";
import Loading from "../AdminComponents/Loading";
import { readAllAdminEvents } from "../../ApiCalls/readAllAdminEvents";
import { createNewEvent } from "../../ApiCalls/createNewEvent";
import { useEventAndTimeContext } from "../../State/EventAndTimeContext";
import { getFullEventDataForAllEvents } from "../../ApiCalls/getFullEventData";
import { getHappinessIndex } from "../AdminCalculations/eventsCalc";

const Events = () => {
  // name input field
  const [searchQuery, setSearchQuery] = useState("");

  // stores which event got clicked
  const [clickedEvent, setClickedEvent] = useState(null);

  // when changed, new events will be fetched
  const [eventChange, setEventChange] = useState(false);

  // loading indicators
  const [getEventsLoading, setGetEventsLoading] = useState(true);

  // all events data with reviews
  const [allEventsWithReviews, setAllEventsWithReviews] = useState([]);

  const [filteredEvents, setFilteredEvents] = useState(allEventsWithReviews);

  // global data for events
  const {
    allEvents,
    setAllEvents,
    setSelectedEvent,
    selectedEventId,
    setSelectedEventId,
  } = useEventAndTimeContext();

  const fetchEvents = async () => {
    setGetEventsLoading(true);
    const fetchedEvents = await readAllAdminEvents();
    setGetEventsLoading(false);
    setAllEvents(null);
    await setAllEvents(fetchedEvents);
    setGetEventsLoading(false);
  };

  const fetchEventVotingData = async () => {
    const allEventsWithData = await getFullEventDataForAllEvents();
    setAllEventsWithReviews(allEventsWithData);
    if (searchQuery == "") {
      setFilteredEvents(allEventsWithData);
    }
  };

  // fetch events on change of the eventChange variable
  useEffect(() => {
    const getEvents = async () => {
      try {
        fetchEvents();
        fetchEventVotingData();
      } catch (error) {
        console.error("Error while fetching event data:", error);
      }
    };
    getEvents();
  }, [eventChange]);

  // handle popup behaviour
  const openPopUp = (event) => {
    setClickedEvent(event);
    console.log(event.openForVotings);
  };
  const closePopUp = () => {
    setClickedEvent(null);
    setEventChange(!eventChange);
  };

  // update global array on deletion
  const deleteEvent = (eventId) => {
    let updatedEvents = allEvents.filter(function checkId(event) {
      return event.eventId !== eventId;
    });
    setAllEvents(updatedEvents);
    // edge case: deleted event was currently selected -> switch to newest
    if (
      selectedEventId === eventId &&
      selectedEventId !== allEvents[0].eventId
    ) {
      setSelectedEvent(allEvents[0].eventName);
      setSelectedEventId(allEvents[0].eventId);
    }
    // edge case: deleted event was the newest one -> switch to second newest
    else if (
      selectedEventId === eventId &&
      selectedEventId === allEvents[0].eventId
    ) {
      setSelectedEvent(allEvents[1].eventName);
      setSelectedEventId(allEvents[1].eventId);
    } else {
    }
  };

  useEffect(() => {
    let query = searchQuery.toUpperCase();
    if (query != "") {
      let searchResults = [];
      allEventsWithReviews.map((event) => {
        if (event.eventName.toUpperCase().includes(query)) {
          searchResults.push(event);
        }
      });
      setFilteredEvents(searchResults);
    } else {
      setAllEventsWithReviews(allEventsWithReviews);
    }
  }, [searchQuery]);

  return (
    <div className="p-12 flex flex-col justify-start gap-5 text-darkBlue w-full">
      <h1 className="font-bold text-5xl">
        All Events<span className="text-red">.</span>
      </h1>

      <input
        className=" w-full flex p-3 bg-white rounded-lg shadow-lg"
        placeholder="Search for an event"
        value={searchQuery}
        onInput={(e) => setSearchQuery(e.target.value)}
      />

      <div className="h-[30vh] lg:max-h-fit lg:h-[70vh]  flex flex-col rounded-lg shadow-lg gap-5 p-5">
        <div className="grid grid-cols-6">
          <p className="col-span-3">Name & ID</p>
          <p className="">Total votes</p>
          <p className="">Average Voting</p>
          <p className="">Status</p>
        </div>
        <Loading text="Events" dependsOn={getEventsLoading} />
        <div className="flex flex-col overflow-y-auto">
          <div className=" flex flex-col gap-5">
            {filteredEvents !== null &&
              filteredEvents.map((event) => (
                <div
                  onClick={() => openPopUp(event)}
                  key={event.timestamp}
                  className="fadeIn bg-offWhite flex flex-row  justify-between gap-2 cursor-pointer rounded-lg p-3"
                >
                  <div
                    className="grid grid-cols-6 justify-between w-full items-center"
                    key={event.timestamp}
                  >
                    <div className="col-span-3">
                      <h1 className="text-xl font-bold ">{event.eventName}</h1>
                      <p>{event.eventId}</p>
                    </div>
                    <div>
                      <p className="font-bold">{event.votes.length}</p>{" "}
                    </div>
                    <div>
                      <p>
                        {event.votes.length ? (
                          <p className="font-bold">
                            {getHappinessIndex(event.votes)}
                          </p>
                        ) : (
                          "-/-"
                        )}
                      </p>
                    </div>
                    <div>
                      <p
                        className={`${
                          event.openForVotings ? "text-good" : "text-bad"
                        }`}
                      >
                        {event.openForVotings ? "OPEN" : "CLOSED"}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            {clickedEvent && (
              <div className="centered-popup">
                <div className="backdrop" onClick={closePopUp}>
                  <EventDetailView
                    eventID={clickedEvent.eventId}
                    name={clickedEvent.eventName}
                    createdAt={clickedEvent.timestamp}
                    onClose={closePopUp}
                    onDelete={() => deleteEvent(clickedEvent.eventId)}
                    openForVotings={clickedEvent.openForVotings}
                    userId={clickedEvent.userId}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
