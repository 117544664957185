import { auth } from "../firebase.config";
import { signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";

// sets up the google auth provider
const Provider = new GoogleAuthProvider();
Provider.setCustomParameters({ prompt: "select_account" });

// logs in a new admin and redirects to the admin frontend on success.
export function logInAdmin() {
  return new Promise((resolve) => {
    signInWithPopup(auth, Provider) // signInWithPopup opens a popup for the login
      .then((response) => {
        resolve(response.user);
        console.log(response);
        window.location.assign(window.location.origin + "/admin/home"); //redirects to the events page
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

export function logOut() {
  return new Promise(() => {
    signOut(auth)
      /*.then(() => {
          window.location.assign(window.location.origin + "/admin"); //redirects to the admin login page
        })*/
      .catch((error) => {
        console.error(error);
      });
  });
}
