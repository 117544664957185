import Menu from "./Admin/AdminComponents/Menu";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Import of all pages:

import Insights from "./Admin/AdminPages/Insights";
import Profile from "./Admin/AdminPages/Profile";
import LogIn from "./Admin/AdminPages/LogIn";
import MoodPage from "./User/MoodPage";
import UserPrivacyPolicy from "./User/UserPrivacyPolicy";
import NotFound from "./User/NotFound";
import Events from "./Admin/AdminPages/Events";
import EnterIDPage from "./User/EnterIDPage";
import ThanksForVotingPage from "./User/ThanksForVotingPage";

import { useAuthContext } from "./State/AuthContext";
import AdminRoutes from "./Admin/AdminComponents/AdminRoutes";
import EventClosedPage from "./User/EventClosedPage";
import Home from "./Admin/AdminPages/Home";
import UpdatedVotePage from "./User/UpdatedVotePage";

function App() {
  const { userState } = useAuthContext();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 730;
  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <Router>
      <div
        className={`${
          width < breakpoint ? "flex-col" : "flex"
        } w-[100vw] justify-between`}
      >
        {userState === "admin" && <Menu className="fixed" />}

        <Routes>
          <Route path="/" element={<EnterIDPage />} />
          <Route path="/event/:id" element={<MoodPage />} />
          <Route path="/thankyou" element={<ThanksForVotingPage />} />
          <Route path="/updated" element={<UpdatedVotePage />} />
          <Route path="/event-closed" element={<EventClosedPage />} />
          <Route path="/privacypolicy" element={<UserPrivacyPolicy />} />

          <Route path="/admin">
            <Route path="" element={<LogIn />} />
            <Route element={<AdminRoutes />}>
              <Route path="/admin/home" element={<Home />} />
              <Route path="/admin/insights" element={<Insights />} />
              <Route path="/admin/events" element={<Events />} />
              <Route path="/admin/profile" element={<Profile />} />
            </Route>
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
