import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callDeleteEvent = httpsCallable(functions, "delete_event");

export async function deleteEvent(event_ID) {
  try {
    const result = await callDeleteEvent({ event_ID: event_ID });
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
}
