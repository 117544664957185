import React from "react";
import { createBrowserRouter } from "react-router-dom";

const LoadingProps = {
  text: String,
  dependsOn: Boolean,
};

const Loading = (LoadingProps) => {
  if (LoadingProps.dependsOn) {
    return (
      <div className="h-[100%] w-[100%] flex justify-center items-center">
        <p className="text-xl font-bold px-7 py-3 bg-blue text-white rounded-lg fadeIn">
          {LoadingProps.text} are loading...
        </p>
      </div>
    );
  } else {
  }
};

export default Loading;
