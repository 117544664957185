import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callFunctionUpdateEventStatus = httpsCallable(
  functions,
  "update_event_status"
);

export async function UpdateEventStatus(eventId) {
  try {
    const result = await callFunctionUpdateEventStatus({
      event_id: eventId,
    });
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
}
