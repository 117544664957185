const EventClosedPage = () => {
  return (
    <div className="p-12 text-darkBlue text-center lg:text-left flex flex-col gap-5 w-[100vw] h-[100vh] justify-center items-center">
      <img src="/icons/ProfilePageNinja.svg" alt="" />
      <h1 className="font-bold text-5xl">
        A bit <span className="text-red">too late</span>...
      </h1>
      <h2 className="font-bold text-2xl">
        Sorry, this event <span className="text-red">is closed</span>.
      </h2>
    </div>
  );
};

export default EventClosedPage;
