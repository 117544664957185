const UpdatedVotePage = () => {
  return (
    <div className="p-12 text-darkBlue text-center lg:text-left flex flex-col gap-5 w-[100vw] h-[100vh] justify-center items-center">
      <img src="/icons/ProfilePageNinja.svg" alt="" />
      <h1 className="font-bold text-5xl">
        Nice to <span className="text-red">see you again</span>!
      </h1>
      <h2 className="font-bold text-2xl">
        We <span className="text-red">updated</span> your previous vote.
      </h2>
    </div>
  );
};

export default UpdatedVotePage;
