import { logInAdmin } from "../../Auth/adminAuth";

//Calls the cloud function "CreateNewAdminInDB" that creates a new Admin User in the Database

//axios
//Jest
//playwright

const LogIn = () => {
  return (
    <div className="h-[100vh] w-[100vw] flex flex-col gap-10 justify-center items-center">
      <div className="flex gap-10 items-center">
        <img
          className="w-[100px] lg:w-[150px]"
          src="../icons/ProfilePageNinja.svg"
          alt="ninja"
        />
        <h1 className="text-4xl lg:text-7xl font-bold text-darkblue">
          Nord
          <span className="text-blue text-xl lg:text-4xl">mood</span>
        </h1>
      </div>

      <div className="flex gap-5 shadow-lg max-w-[90vw] rounded-lg">
        <div className="flex flex-col  p-12 gap-5">
          <h1 className="font-bold text-3xl text-center">
            Login / Register
            <span className="text-red">.</span>
          </h1>

          <button
            className="bg-blue rounded-lg text-white cursor-pointer hover:bg-darkBlue transition-all 500ms py-2"
            onClick={() => logInAdmin()}
          >
            Sign In with Google
          </button>
          <p className="text-sm text-center opacity-70">
            When signing in, you accept our{" "}
            <a className="text-blue" href="/privacypolicy">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
