function getTextReviewsLength(data) {
  let counter = 0;

  data &&
    data.map((data) => {
      if (data.text) {
        counter++;
      } else {
      }
    });
  return counter;
}

function getHappinessIndex(data) {
  let SummedRating = 0;
  data &&
    data.map((vote) => {
      SummedRating += vote.rating;
    });

  const realLength = data && data.length;
  const HappinessIndex = SummedRating / realLength;
  const HappinessIndexRounded = Math.round(HappinessIndex * 10) / 10;
  return HappinessIndexRounded;
}

function ratingToSmiley(rating) {
  if (rating == 5) {
    return "GoodSmiley.svg";
  } else if (rating == 0) {
    return "MediumSmiley.svg";
  } else if (rating == -5) {
    return "BadSmiley.svg";
  }
}

export { getTextReviewsLength, getHappinessIndex, ratingToSmiley };
