import { Link } from "react-router-dom";

const UserPrivacyPolicy = () => {
  return (
    <div className="p-12 text-darkBlue flex flex-col gap-5">
      <Link to="/">
        <button className="text-white bg-blue w-[10em] py-4 rounded-lg transition-all 500ms ">
          Back
        </button>
      </Link>

      <h1 className="font-bold text-5xl">
        Privacy Policy<span className="text-red">.</span>
      </h1>
    </div>
  );
};

export default UserPrivacyPolicy;
