import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callReadVotes = httpsCallable(
  functions,
  "read_votes_based_on_timeperiod"
);

export async function readVotes(startDate, endDate, eventId) {
  try {
    console.log(
      `fetching votes with startdate: ${startDate} and enddate: ${endDate} for the event: ${eventId}`
    );
    const result = await callReadVotes({
      startDate: startDate,
      endDate: endDate,
      eventId: eventId,
    });
    console.log("result from fetching votes:", result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
}
