import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callWriteReview = httpsCallable(functions, "write_review");

export async function writeReview(eventId, text, userId, rating) {
  try {
    const result = await callWriteReview({
      event_id: eventId,
      rating: rating,
      text: text,
      user_id: userId,
    });
    console.log(result.data);
    return result.data[1];
  } catch (error) {
    console.log(error);
  }
}
