import { createContext, useState, useContext, useMemo, useEffect } from "react";

// imports the global auth instance
import { auth } from "../firebase.config";

// imports the listener provided by firebase auth that listens to every change in the auth system.
import { onAuthStateChanged } from "firebase/auth";

// creates the context
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // the user state represents the current auth state of the user. It's either "unauthenticated", "anonymous" or "admin"
  const [userState, setUserState] = useState("unauthenticated");

  // this constant saves the current user object. If a user is unauthenticated its null.
  const [currentUser, setCurrentUser] = useState(null);

  // useEffect is used to create only one listener.
  useEffect(() => {
    // listens to all auth changes and sets the currentUser
    onAuthStateChanged(auth, (user) => {
      if (user && user.isAnonymous) {
        setCurrentUser(user);
        setUserState("anonymous");
        console.log(`Listened to Auth changes and changed user to anoymous`);
      } else if (user && user.isAnonymous == false) {
        setCurrentUser(user);
        setUserState("admin");
      } else if (user == null) {
        setCurrentUser(null);
        setUserState("unauthenticated");
      } else {
        console.log("An authentication error ocurred.");
        setCurrentUser(null);
        setUserState("unauthenticated");
      }
    });
  }, []);

  const contextValue = useMemo(() => {
    return {
      userState,
      currentUser,
    };
  }, [userState, currentUser]);
  return (
    <AuthContext.Provider value={contextValue}>
      {children /* the whole application */}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

// this provider wraps the whole application in index.js
export default AuthProvider;
