import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { writeReview } from "../ApiCalls/writeReview";
import { useAuthContext } from "../State/AuthContext";
import { logOut } from "../Auth/adminAuth";
import { signInAnoymousUser } from "../Auth/userAuth";
import { readEventData } from "../ApiCalls/readEventData";
import { useSearchParams } from "react-router-dom";

const allOptions = [
  {
    color: "#25A460", //use colors out of tailwind.config.js
    icon: "GoodSmiley.svg",
    rating: 5,
  },
  {
    color: "#F5B62d", //use colors out of tailwind.config.js
    icon: "MediumSmiley.svg",
    rating: 0,
  },
  {
    color: "#DE4444", //use colors out of tailwind.config.js
    icon: "BadSmiley.svg",
    rating: -5,
  },
];

const MoodPage = () => {
  const [eventName, setEventName] = useState("");
  const [submitVoteLoading, setSubmitVoteLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [activeMood, setActiveMood] = useState("#25A460");
  const [text, setText] = useState("");
  const [rating, setRating] = useState(5);

  const { currentUser, userState } = useAuthContext();

  useEffect(() => {
    authenticateUser();
    readEvent();
  }, []);

  useEffect(() => {
    autoSubmitViaParams();
  }, [currentUser]);

  const authenticateUser = () => {
    if (userState === "admin") {
      logOut().then(() => signInAnoymousUser());
      console.log(
        "User got signed in anoymously because he was authenticated as an admin"
      );
    } else if (userState === "unauthenticated") {
      signInAnoymousUser();
      console.log(
        "User got signed in anoymously because he was unauthenticated"
      );
    }
  };

  const autoSubmitViaParams = () => {
    const paramsRating = searchParams.get("rating");
    if (
      (paramsRating == -5 || paramsRating == 0 || paramsRating == 5) &&
      currentUser
    ) {
      setRating(paramsRating);
      handleSubmit();
    }
  };

  const readEvent = async () => {
    try {
      let eventId = getEventId();
      const eventData = await readEventData(eventId);
      setEventName(eventData.eventName);
    } catch (error) {
      console.error("Error while fetching event data:", error);
    }
  };

  const getEventId = () => {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const eventId = path.split("/").filter(Boolean).pop();
    return eventId;
  };

  async function handleSubmit() {
    setSubmitVoteLoading(true);
    const eventId = getEventId();

    let result = await writeReview(eventId, text, currentUser.uid, rating);
    if (result == "submitted") {
      window.location.replace("/thankyou");
    } else if (result == "updated") {
      window.location.replace("/updated");
    } else if (result == "event-closed") {
      window.location.replace("/event-closed");
    }
    setSubmitVoteLoading(false);
  }

  return (
    <div className="h-[100vh] w-[80vw] mx-auto lg:py-auto py-2 flex flex-col gap-12 justify-center items-center">
      <div className="flex flex-col gap-1 text-center">
        <h1 className="text-5xl lg:text-7xl font-bold text-darkblue">
          Nord
          <span
            className={`lg:text-4xl transition-all 500ms`}
            style={{ color: activeMood }}
          >
            mood
          </span>
        </h1>
        {eventName && (
          <h2 className="font-bold text-xl">
            {eventName}
            <span style={{ color: activeMood }}>.</span>
          </h2>
        )}
      </div>
      <div className="flex flex-col gap-10 w-full lg:w-[80%]">
        <div className="flex flex-row flex-wrap items-center lg:gap-5 gap-2 justify-evenly">
          {allOptions.map((option) => (
            <div
              data-testid={option.rating}
              key={option.color}
              onClick={() => {
                setActiveMood(option.color);
                setRating(option.rating);
              }}
              className={`aspect-square w-1/4 transition-all 500ms flex justify-center items-center cursor-pointer rounded-2xl max-w-[33%] min-w-[130px]`}
              style={{
                boxShadow:
                  option.color === activeMood
                    ? `0 0 30px ${activeMood}50`
                    : "0 0 30px #00000030",
                borderWidth: option.color === activeMood ? "4px" : "0",
                borderColor: option.color,
              }}
            >
              <img
                src={`../icons/${option.icon}`}
                className="w-[80%]"
                draggable="false"
                alt="Smiley"
              />
            </div>
          ))}
        </div>

        <button
          onClick={() => handleSubmit()}
          className={`w-fit px-7  py-4 rounded-lg self-center text-white transition-all 500ms`}
          style={{ backgroundColor: activeMood }}
        >
          {submitVoteLoading ? "Loading..." : "Submit"}
        </button>
      </div>

      <textarea
        type="text-field"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="OPTIONALLY you can add a text review here"
        rows="2"
        maxLength="512"
        className="w-[100%] rounded-xl bg-offWhite p-5 centered-input-placeholder"
      ></textarea>
      <Link to="/privacypolicy">
        <p className="self-end opacity-50">Privacy Policy</p>
      </Link>
    </div>
  );
};

export default MoodPage;
