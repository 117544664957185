import { useAuthContext } from "../../State/AuthContext";
import { logOut } from "../../Auth/adminAuth";

const Profile = () => {
  const { currentUser, userState } = useAuthContext();
  return (
    <div className="p-12 flex flex-col justify-between gap-5 text-darkBlue w-full">
      <h1 className="font-bold text-5xl">
        Your Profile<span className="text-red">.</span>
      </h1>
      <div className="w-[100%] h-full flex justify-center items-center">
        <div className="rounded-lg shadow-lg p-12   flex flex-col justify-center items-center gap-10">
          <img
            className="w-[150px] h-[150px]"
            src="../icons/ProfilePageNinja.svg"
            alt="ninja"
          />
          <div className="flex flex-col gap-2 text-center">
            <h2 className="font-bold text-3xl">{currentUser?.displayName}</h2>
            <p className="text-sm">{currentUser?.email}</p>
            <p className="text-xs uppercase text-darkBlue opacity-40">
              {userState}
            </p>
          </div>
          <button onClick={logOut}>
            <div className="rounded-lg text-white bg-red px-9 py-3">
              <p className="text-lg font-medium">Log out</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
