import { useState, useEffect, useRef } from "react";
import { ratingToSmiley } from "../AdminCalculations/eventsCalc";
import { readVotes } from "../../ApiCalls/readVotesBasedOnTimeperiod";
import { useEventAndTimeContext } from "../../State/EventAndTimeContext";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import { readAllAdminEvents } from "../../ApiCalls/readAllAdminEvents";
import TimePeriodSelector from "../AdminComponents/TimePeriodSelector";
import {
  getHappinessIndex,
  getTextReviewsLength,
} from "../AdminCalculations/eventsCalc";
import Loading from "../AdminComponents/Loading";
import {
  convertDateToReadableWithYear,
  convertTimestampToReadableWithYear,
} from "../AdminCalculations/dateCalc";
import InsightsPrint from "../AdminComponents/InsightsPrint";

const Insights = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { startdate, enddate, selectedEventId, selectedEvent } =
    useEventAndTimeContext();

  const happinessIndex = getHappinessIndex(data);
  const textReviews = getTextReviewsLength(data);

  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `Nordmood report for ${selectedEvent}`,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const fetchedData = await readVotes(
          startdate,
          enddate,
          selectedEventId
        );
        setData(null);
        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error("Error in App:", error);
      }
    };
    getData();
  }, [selectedEventId, startdate, enddate]);

  return (
    <div className="p-12 flex flex-col justify-between fadeIn gap-5 text-darkBlue w-full">
      <div className="flex justify-between">
        <h1 className="font-bold text-5xl">
          Insights<span className="text-red">.</span>
        </h1>
      </div>
      <div className="flex gap-10 flex-wrap">
        <EventSelector />
        <TimePeriodSelector preText="Timeperiod" />
      </div>

      <div style={{ display: "none" }}>
        <InsightsPrint ref={ref} handlePrint={handlePrint} />
      </div>
      <div className="h-[60vh] lg:h-[65vh] overflow-y-auto rounded-lg shadow-lg flex flex-col gap-5 p-5">
        <div className="flex justify-between items-center gap-5">
          <h3 className="font-bold text-sm">
            Report from{" "}
            <span className="text-2xl">
              {convertDateToReadableWithYear(startdate)}
            </span>{" "}
            till{" "}
            <span className="text-2xl">
              {convertDateToReadableWithYear(enddate)}
            </span>
          </h3>

          <button
            onClick={handlePrint}
            className="px-4 py-2 rounded-lg bg-blue text-white font-bold text-sm border-2 border-blue hover:text-blue hover:bg-white transition-all"
          >
            Print
          </button>
        </div>
        <div className="grid-container gap-5">
          <div className="rounded-lg p-5 text-center flex flex-col gap-5">
            <h3 className="font-bold">App usage</h3>
            <div className="">
              <p className="text-5xl font-bold">
                {data && data.length ? data.length : "0"}
              </p>
              <p className="font-light">total votes</p>
            </div>
          </div>
          <div className="rounded-lg p-5 flex flex-col gap-5 text-center">
            <h3 className="font-bold">Happiness Index</h3>
            <div>
              <p className="text-5xl font-bold">
                <span className="text-3xl">
                  {happinessIndex >= 0
                    ? "+"
                    : happinessIndex === "N/A"
                    ? ""
                    : ""}
                </span>
                {data && data.length ? happinessIndex : 0}
              </p>
              <p className="font-light">from -5 to +5</p>
            </div>
          </div>
          <div className="rounded-lg p-5 text-center flex flex-col gap-5">
            <h3 className="font-bold">Text reviews</h3>
            <div className="">
              <p className="text-5xl font-bold">
                {data && data && data.length ? textReviews : 0}
              </p>
              <p className="font-light">total reviews</p>
            </div>
          </div>
        </div>
        <Loading text="Stats and Reviews" dependsOn={loading} />
        <div>
          {data && data.length !== 0 && loading == false && (
            <h3 className="font-bold text-xl mb-5">All submitted reviews</h3>
          )}
          <div
            className={
              data && data.length !== 0
                ? "grid-container gap-10"
                : "flex flex-col w-full items-center justify-center"
            }
          >
            {data &&
              data.length !== 0 &&
              loading == false &&
              data.map((review, index) => (
                <div className="flex items-center fadeIn gap-5" key={index}>
                  <img
                    className="w-12"
                    src={`/icons/${ratingToSmiley(review.rating)}`}
                    alt="Smiley"
                  />
                  <div className="flex flex-col gap-2">
                    <p>
                      {review.text ? (
                        review.text
                      ) : (
                        <span className="italic text-darkBlue opacity-40">
                          No text review
                        </span>
                      )}
                    </p>
                    <p className=" text-darkBlue opacity-60">
                      {convertTimestampToReadableWithYear(review.timestamp)}
                    </p>
                    <p className="opacity-50">{review.createdAt}</p>
                  </div>
                </div>
              ))}
            {data && data.length === 0 && loading == false && (
              <div className="p-5 bg-offWhite flex flex-col gap-4 w-[70%] rounded-lg text-center">
                <h3 className="font-bold text-xl">Looks empty here!</h3>
                <p>
                  There are no reviews for the event{" "}
                  <span className="font-bold">{selectedEvent}</span> from{" "}
                  <span className="font-bold">
                    {convertDateToReadableWithYear(startdate)}
                  </span>{" "}
                  to{" "}
                  <span className="font-bold">
                    {convertDateToReadableWithYear(enddate)}
                  </span>
                  . Please check whether this is the correct event and period or
                  give you users the ID{" "}
                  <span className="font-bold">{selectedEventId}</span> to vote.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;

const EventSelector = () => {
  const [isOpen, setIsOpen] = useState(false); //checks wether the event selection menu is open or not

  const {
    selectedEvent,
    setSelectedEvent,
    setSelectedEventId,
    allEvents,
    setAllEvents,
  } = useEventAndTimeContext();

  document.onkeydown = function (e) {
    if (e.key === "Escape") {
      // Escape key pressed
      setIsOpen(false);
    }
  };

  const getData = async () => {
    try {
      const fetchedData = await readAllAdminEvents();
      setAllEvents(null);
      setAllEvents(fetchedData);
      setSelectedEvent(fetchedData[0].eventName);
      setSelectedEventId(fetchedData[0].eventId);
    } catch (error) {
      // Handle errors here
      console.error("Error in App:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //handles the click on the events selector button
  const closePopUp = () => {
    setIsOpen(false);
    console.log("closePopUp");
  };

  const openPopUp = async () => {
    try {
      console.log("Opened Menu");
    } catch (error) {
      console.error("Error in App:", error);
    }
    setIsOpen(true);
  };

  // avoids that the click on the popup itself triggers the closing of it
  const handlePopUpClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="flex gap-3 items-center rounded-lg p-2 relative">
      <h3 className="text-darkBlue text-lg">Event:</h3>
      <button
        onClick={() => {
          openPopUp();
        }}
      >
        <div className="bg-offWhite hover:bg-offWhite transition-all 500ms p-3 gap-2 rounded-lg flex">
          <p>{selectedEvent}</p>
          <img src="/icons/ArrowDown.svg" alt="" />
        </div>
      </button>
      {isOpen && (
        <div className="bg-darkBlue backdrop " onClick={closePopUp}>
          <div className="centered-popup fadeIn" onClick={handlePopUpClick}>
            <div className="bg-white shadow-2xl rounded-lg visible">
              <h3 className="text-2xl font-bold px-8 py-4">
                Select an event<span className="text-red">.</span>
              </h3>
              <div className="flex flex-col gap-2 max-h-[60vh] overflow-y-auto">
                {allEvents !== undefined &&
                  allEvents.map((event) => (
                    <button
                      key={event.eventName}
                      onClick={() => {
                        setSelectedEvent(event.eventName);
                        setSelectedEventId(event.eventId);
                        setIsOpen(false);
                      }}
                      className="py-6 px-8 hover:bg-offWhite bg-white text-darkBlue text-start transition-all cursor-loader 500ms"
                    >
                      <p>{event.eventName}</p>
                    </button>
                  ))}
              </div>
              <Link
                to="/admin/events"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <h3 className="px-8 py-4 bg-offWhite hover:bg-blue hover:text-white transition-all 500ms rounded-b-lg">
                  + Create new one
                </h3>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
