// This component displays the menu bar for the admin menu
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../../State/AuthContext";

// All menu items
const MenuItems = [
  {
    name: "Home",
    link: "admin/home",
    icon: "Home.png",
  },
  {
    name: "Insights",
    link: "admin/insights",
    icon: "Insights.png",
  },
  {
    name: "All Events",
    link: "admin/events",
    icon: "Events.png",
  },
];

// AllRoutes is an array of all valid admin frontend Routes. It gets used to check wheter the page exisits or not. This gets used to only show the admin menu on the admin front end pages
const AllRoutes = [
  "admin/insights",
  "admin/privacypolicy",
  "admin/events",
  "admin/profile",
  "admin/home",
];

// Button that links to the Profile Page

const ProfileButton = () => {
  const { currentUser } = useAuthContext();

  return (
    <Link
      to="/admin/profile"
      className=" bg-blue hover:bg-lightBlue text-white transition-all hover:text-darkBlue 500ms rounded-lg hover:cursor-pointer p-3"
    >
      <div className="flex gap-5">
        <img src="../icons/ProfileButtonNinja.svg" alt="ninja" />
        <div className="flex flex-col justify-center ">
          <p className="text-sm font-light">Logged in as:</p>
          <p className="text-xl font-semibold">{currentUser?.displayName}</p>
          <p className="text-[10px] font-light">{currentUser?.email}</p>
        </div>
      </div>
    </Link>
  );
};

// Main component that will be exported
const Menu = () => {
  // get current route to display active Tab
  const location = useLocation();
  let currentRoute = location.pathname.replace("/", "");

  //check for valid admin page
  const validAdminRoute = () => {
    if (AllRoutes.includes(currentRoute)) {
      return true;
    } else {
      return false;
    }
  };
  const [mobileMenu, setMobileMenu] = useState();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 730;

  const MobileMenu = () => {
    return (
      <div className="backdrop fadeIn">
        <div className="centered-popup fadeIn rounded-lg flex gap-4 flex-col items-center justify-center bg-white p-4">
          <div className="flex flex-col gap-4 bg-white p-2 rounded-lg">
            {MenuItems.map((item) => {
              let activeTab = false;
              if (item.link === currentRoute) {
                activeTab = true;
              } else {
                activeTab = false;
              }
              //maps through the array of Menu Items and displays a div for each of them with name, icon and link
              return (
                <Link
                  to={`${item.link}`}
                  draggable="false"
                  key={item.link}
                  className={`rounded-lg bg-white p-2 border-l-8 hover:border-red transition-all 500ms hover:cursor-pointer ${
                    activeTab ? "border-red" : "border-white"
                  }`}
                >
                  <div className="flex gap-5 items-center">
                    <img
                      className="aspect-square w-9"
                      src={`../icons/${item.icon}`}
                      draggable="false"
                      alt={item.name}
                    />
                    <h3 className="text-lg ">{item.name}</h3>
                  </div>
                </Link>
              );
            })}
          </div>
          <div
            className="p-3 bg-red cursor-pointer w-fit px-12 rounded-lg"
            onClick={() => {
              setMobileMenu(false);
            }}
          >
            <p className="text-white">Close</p>
          </div>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (validAdminRoute() && width > breakpoint) {
    return (
      <div className="bg-darkBlue sm:min-[75vw] md:min-w-[50vw] lg:min-w-[30vw] h-[100vh] flex flex-col gap-5 justify-between p-12 rounded-tr-[50px]">
        <div className="flex gap-5 items-center justify-start">
          <img className="w-10 rounded-sm" src="../icons/NRDMOD.png" />

          <h1 className="text-5xl font-bold text-white">
            Nord
            <span className="text-lightBlue text-2xl">mood</span>
          </h1>
        </div>

        <div className="flex flex-col gap-4 bg-white p-2 rounded-lg">
          {MenuItems.map((item) => {
            let activeTab = false;
            if (item.link === currentRoute) {
              activeTab = true;
            } else {
              activeTab = false;
            }
            //maps through the array of Menu Items and displays a div for each of them with name, icon and link
            return (
              <Link
                to={`${item.link}`}
                draggable="false"
                key={item.link}
                className={`rounded-lg bg-white p-2 border-l-8 hover:border-red transition-all 500ms hover:cursor-pointer ${
                  activeTab ? "border-red" : "border-white"
                }`}
              >
                <div className="flex gap-5 items-center">
                  <img
                    className="w-9"
                    src={`../icons/${item.icon}`}
                    draggable="false"
                    alt={item.name}
                  />
                  <h3 className="text-lg ">{item.name}</h3>
                </div>
              </Link>
            );
          })}
        </div>

        <div className="flex flex-col gap-3">
          <ProfileButton />
          <div className="flex justify-center">
            <Link to="/privacypolicy" className="text-offWhite text-center">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (validAdminRoute() && width < breakpoint) {
    return (
      <div className="flex flex-wrap gap-2 m-2 justify-center">
        <div
          onClick={() => setMobileMenu(true)}
          className="no-select-text flex items-center text-center px-5 py-3 shadow-lg bg-blue text-white font-bold rounded-lg"
        >
          Menu
        </div>
        {mobileMenu && <MobileMenu />}
      </div>
    );
  } else {
  }
};

export default Menu;
