import { readAllAdminEvents } from "./readAllAdminEvents";
import { readVotes } from "./readVotesBasedOnTimeperiod";

export async function getFullEventDataForAllEvents() {
  try {
    // get all events with their metadata
    const allEvents = await readAllAdminEvents();
    console.log(allEvents);

    // set startdate for reading votes to year 2000 so it shows all votes ever submitted
    // set enddate for reading votes to today so all votes get retrieved
    let today = new Date();
    const year2000 = new Date();
    year2000.setYear(2000);
    const defaultToday = today.toISOString().split("T")[0];
    const defaultMonthAgo = year2000.toISOString().split("T")[0];

    // get votes for each event and add it to the event data

    for (let i = 0; i < allEvents.length; i++) {
      console.log(`iterating for event: ${allEvents[i]}`);
      const votes = await readVotes(
        defaultMonthAgo,
        defaultToday,
        allEvents[i].eventId
      );
      allEvents[i]["votes"] = votes;
      console.log(`Found votes: ${votes.toString()}`);
    }

    console.log(allEvents);
    return allEvents;
  } catch (error) {
    console.log(error);
  }
}
