import React, { useState, useEffect } from "react";
import { createNewEvent } from "../../ApiCalls/createNewEvent";
import { getFullEventDataForAllEvents } from "../../ApiCalls/getFullEventData";
import { getHappinessIndex } from "../AdminCalculations/eventsCalc";

const Home = () => {
  const [newEventName, setNewEventName] = useState("");
  const [eventCreationLoading, setEventCreationLoading] = useState();

  const [allEventsWithReviews, setAllEventsWithReviews] = useState(null);

  async function handleEventCreation() {
    // start loading indicator
    setEventCreationLoading(true);
    // create the event and store the data about the event
    let newEvent = await createNewEvent(newEventName);
    console.log(`created event: ${newEvent}`);
    setEventCreationLoading(false);
  }

  const fetchEventVotingData = async () => {
    const allEventsWithData = await getFullEventDataForAllEvents();
    setAllEventsWithReviews(allEventsWithData);
  };

  const getTotalVoteCount = (allEvents) => {
    let totalVoteCount = 0;
    allEvents.map((event) => {
      if (event.votes.length != 0) {
        totalVoteCount += event.votes.length;
      }
    });

    return totalVoteCount;
  };

  const getAllTimeHappinessIndex = (allEvents) => {
    const happinessIndexOfAllEvents = [];
    allEvents.map((event) => {
      if (getHappinessIndex(event.votes)) {
        happinessIndexOfAllEvents.push(getHappinessIndex(event.votes));
      }
    });

    let indexSum = 0;
    happinessIndexOfAllEvents.map((index) => {
      indexSum += index;
    });

    const finalIndex = indexSum / allEvents.length;
    return Math.round(finalIndex * 10) / 10;
  };

  useEffect(() => {
    try {
      fetchEventVotingData();
      console.log(allEventsWithReviews);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="p-12 flex flex-col justify-start gap-5 text-darkBlue w-full">
      <h1 className="font-bold text-5xl">
        Home<span className="text-red">.</span>
      </h1>
      <div className="rounded-lg flex gap-10">
        <div className="grid gap-10 grid-cols-2 w-full">
          <div className="rounded-lg flex flex-col shadow-lg p-5 gap-5 w-full">
            <h3 className="font-bold text-2xl">
              Your last event<span className="text-red">.</span>
            </h3>
            <div className="grid grid-cols-2 h-full gap-5">
              <p className="font-bold">Name:</p>
              <p>
                {allEventsWithReviews != null &&
                  allEventsWithReviews[0].eventName}
              </p>
              <p className="font-bold">Total votes:</p>
              <p>
                {allEventsWithReviews != null &&
                  allEventsWithReviews[0].votes.length}
              </p>
            </div>
            <div className="flex flex-row gap-2 items-end">
              <h3 className="font-bold text-5xl">
                {allEventsWithReviews != null &&
                  getHappinessIndex(allEventsWithReviews[0].votes)}
              </h3>
              <p className="font-light text-xs">Happiness Index</p>
            </div>
          </div>

          <div className="rounded-lg flex flex-col shadow-lg p-5 gap-5 w-full">
            <h3 className="font-bold text-2xl">
              Create new event<span className="text-red">.</span>
            </h3>
            <div className="grid grid-cols-3 gap-5">
              <input
                value={newEventName}
                onChange={(e) => setNewEventName(e.target.value)}
                type="text"
                placeholder="Event name"
                className="bg-offWhite p-3 rounded-lg col-span-2"
              />
              <button
                type="submit"
                onClick={() => {
                  if (newEventName !== "") {
                    handleEventCreation();
                    setNewEventName("");
                  } else {
                  }
                }}
                className={`${
                  eventCreationLoading ? "bg-darkBlue" : "bg-blue"
                } p-3 transition-all 500ms rounded-lg text-white font-bold`}
              >
                {eventCreationLoading ? "Loading..." : "+ Add"}
              </button>
            </div>
          </div>
          <div className="rounded-lg flex flex-col shadow-lg p-5 col-span-2 gap-5 w-full">
            <h3 className="font-bold text-2xl">
              All time statistics<span className="text-red">.</span>
            </h3>
            <div className="grid grid-cols-2  gap-5">
              <p className="font-bold">Average happiness index:</p>
              <p>
                {allEventsWithReviews != null &&
                  getAllTimeHappinessIndex(allEventsWithReviews)}
              </p>
              <p className="font-bold">Total events:</p>
              <p>
                {allEventsWithReviews != null && allEventsWithReviews.length}{" "}
                events
              </p>
              <p className="font-bold">Total votes:</p>
              <p>
                {allEventsWithReviews != null &&
                  getTotalVoteCount(allEventsWithReviews)}{" "}
                votes
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
