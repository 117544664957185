import React from "react";

const EventsInfoPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="flex gap-3 p-10 items-center">
      <div className="flex flex-col gap-3">
        <img className="w-12" src="../icons/ProfileButtonNinja.svg" />
        <div className="flex flex-col gap-1">
          <h1 className="text-3xl font-bold ">
            How did you like it<span className="text-red">?</span>
          </h1>
          <h2 className="text-xl font-bold">
            Please let us know<span className="text-red">!</span>
          </h2>
        </div>
        <p>QR Code doesn't work? Visit: {props.eventURL}</p>
      </div>
      <div className="flex flex-col gap-0 items-center justify-center">
        <h2 className="text-xl text-center font-bold">{props.eventName}</h2>
        <img
          className="w-[300px]"
          src={props.qr}
          alt={`qr code for event: ${props.eventName}`}
        />
        <h2 className="text-3xl text-center font-bold">{props.eventId}</h2>
      </div>
    </div>
  );
});

export default EventsInfoPrint;
