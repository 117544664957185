// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getFunctions } from "firebase/functions";

// Firebase web app config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  region: process.env.REACT_APP_REGION,
};

// initializes the firebase app
const app = initializeApp(firebaseConfig);

// initializes firebase authentication
export const auth = getAuth(app);

// initializes firebase cloud functions
export const functions = getFunctions(app, firebaseConfig.region);

// configure firebase app check debug mode if running on localhost
if (window.location.hostname === "localhost") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
} else {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = false;
}

// initializes firebase app check
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    "6LfLW7MnAAAAAIEdniAVzGZsE7AbetdPoZ9c8TmK"
  ),
  isTokenAutoRefreshEnabled: true,
});
