import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

const callFunctionReadAllAdminEvents = httpsCallable(
  functions,
  "read_all_admin_events"
);

export async function readAllAdminEvents() {
  try {
    const result = await callFunctionReadAllAdminEvents();
    console.log(result.data.events);
    return result.data.events;
  } catch (error) {
    console.log(error);
  }
}
