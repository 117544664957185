import QRCode, { create } from "qrcode";
import { useState, useRef, useEffect } from "react";
import { deleteEvent } from "../../ApiCalls/deleteEvent";
import { UpdateEventStatus } from "../../ApiCalls/updateEventStatus";
import { useReactToPrint } from "react-to-print";
import EventsInfoPrint from "../AdminComponents/EventsInfoPrint";
import { convertTimestampToReadableWithYear } from "../AdminCalculations/dateCalc";
import { getUserData } from "../../ApiCalls/getUserData";

const EventDetailViewProps = {
  name: String,
  eventID: Number,
  onClose: Function,
  onDelete: Function,
  openForVotings: Boolean,
  createdAt: String,
  userId: String,
};

const EventDetailView = (EventDetailViewProps) => {
  const [qr, setQr] = useState("");
  const [urlTooltipText, setUrlTooltipText] = useState("Click to copy URL");
  const [idTooltipText, setIdTooltipText] = useState("Click to copy Event ID");
  const [wantsDeletion, setWantsDeletion] = useState(false);
  const [deletionLoading, setDeletionLoading] = useState(false);
  const [eventStatusUpdateLoading, setEventStatusUpdateLoading] =
    useState(false);
  const [isOpenForVotings, setIsOpenForVotings] = useState(
    EventDetailViewProps.openForVotings
  );
  const [createdBy, setCreatedBy] = useState("");

  const url = `${window.location.origin}/event/${EventDetailViewProps.eventID}`;

  const handleEventDeletion = async () => {
    setDeletionLoading(true);
    await deleteEvent(EventDetailViewProps.eventID);
    EventDetailViewProps.onDelete();
    EventDetailViewProps.onClose();
    setDeletionLoading(false);
  };

  const GenerateQRCode = () => {
    QRCode.toDataURL(
      url,
      {
        width: 200,
        color: {
          dark: "#000000",
          light: "#FFFFFF",
        },
      },
      (error, url) => {
        if (error) return console.error(error);
        setQr(url);
      }
    );
  };

  useEffect(() => {
    GenerateQRCode(EventDetailViewProps.eventID);

    async function getUserFullname() {
      try {
        var userName = await getUserData(EventDetailViewProps.userId);
        setCreatedBy(userName);
      } catch (error) {
        console.log(error);
      }
    }

    getUserFullname();
  }, []);

  const updateEventStatusIsOpenForVotings = async () => {
    setEventStatusUpdateLoading(true);
    try {
      const res = await UpdateEventStatus(EventDetailViewProps.eventID);
      console.log(`res: ${res}`);
      if (res === true) {
        setIsOpenForVotings(true);
      } else {
        setIsOpenForVotings(false);
      }
    } catch {
      console.log("Error");
    }
    setEventStatusUpdateLoading(false);
  };

  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `${EventDetailViewProps.name} - Nordmood`,
  });

  document.onkeydown = function (e) {
    if (e.key === "Escape" && wantsDeletion === false) {
      // Escape key pressed
      EventDetailViewProps.onClose();
    } else if (e.key === "Escape" && wantsDeletion === true) {
      setWantsDeletion(false);
    }
  };

  const urlTooltiptext = document.getElementById("url-tooltiptext");
  const idTooltiptext = document.getElementById("id-tooltiptext");

  if (idTooltiptext && urlTooltiptext) {
    window.onmousemove = function (e) {
      let x = e.clientX;
      let y = e.clientY;
      idTooltiptext.style.top = y + 20 + "px";
      idTooltiptext.style.left = x + 20 + "px";
      urlTooltiptext.style.top = y + 20 + "px";
      urlTooltiptext.style.left = x + 20 + "px";
    };
  }

  const copyUrlToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    setUrlTooltipText("Copied!");
    urlTooltiptext.style.background = "#25A460";
    setTimeout(() => {
      setUrlTooltipText("Click to copy URL");
      urlTooltiptext.style.background = "#161632";
    }, "1000");
  };

  const copyIdToClipboard = (id) => {
    navigator.clipboard.writeText(id);
    setIdTooltipText("Copied!");
    idTooltiptext.style.background = "#25A460";
    setTimeout(() => {
      setIdTooltipText("Click to copy Event ID");
      idTooltiptext.style.background = "#161632";
    }, "1000");
  };

  const closeDeletionConfirmationPopUp = (event) => {
    event.stopPropagation();
    setWantsDeletion(false);
  };

  const handlePopUpClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="h-[100vh] flex flex-wrap justify-center items-center fadeIn">
      <div style={{ display: "none" }}>
        <EventsInfoPrint
          ref={ref}
          eventName={EventDetailViewProps.name}
          eventId={EventDetailViewProps.eventID}
          eventURL={url}
          qr={qr}
          host={window.location.host}
        />
      </div>
      <div
        className="flex flex-col h-[95vh] overflow-y-scroll rounded-lg gap-5 bg-white shadow-lg p-5 lg:p-10 max-w-[90vw]"
        onClick={handlePopUpClick}
      >
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <div className="flex flex-col">
            <h1 className="text-xl font-bold text-darkBlue">
              {EventDetailViewProps.name}
            </h1>
            <p className="text-xs">
              Created:{" "}
              {convertTimestampToReadableWithYear(
                EventDetailViewProps.createdAt
              )}{" "}
              by {createdBy}
            </p>
          </div>
          <div className="flex lg:gap-2 gap-4">
            <button
              className={`px-7 z-10 py-2 ${
                deletionLoading ? "bg-red text-white" : "bg-white text-red"
              } rounded-lg hover:bg-red hover:text-white border-red border-2 w-fit transition-all`}
              onClick={() => setWantsDeletion(true)}
            >
              {deletionLoading ? "Deleting..." : "Delete"}
            </button>
            <button
              className=" px-7 py-2 border-blue border-2 rounded-lg text-blue w-fit hover:text-white hover:bg-blue transition-all"
              onClick={EventDetailViewProps.onClose}
            >
              Close
            </button>
          </div>
        </div>
        <div className="gap-5 flex flex-col">
          <div className="flex gap-5 border-2 border-offWhite rounded-lg p-3">
            <h3 className="font-bold text-lg">Name:</h3>
            <p className="text-lg">{EventDetailViewProps.name}</p>
          </div>
          <div
            onClick={() =>
              copyUrlToClipboard(
                `${window.location.origin}/event/${EventDetailViewProps.eventID}`
              )
            }
            className="url-tooltip flex flex-col border-2 border-offWhite rounded-lg p-3 hover:bg-offWhite transition-all cursor-pointer"
          >
            <span id="url-tooltiptext">{urlTooltipText}</span>
            <div className="flex gap-5 ">
              <h3 className="font-bold text-lg">Voting URL:</h3>
              <p>{`${window.location.origin}/event/${EventDetailViewProps.eventID}`}</p>
            </div>
            <p className="text-sm opacity-70">Via this URL users can vote.</p>
          </div>
          <div
            onClick={() => {
              copyIdToClipboard(EventDetailViewProps.eventID);
            }}
            className="id-tooltip flex flex-col border-2 border-offWhite rounded-lg p-3 hover:bg-offWhite transition-all cursor-pointer"
          >
            <span id="id-tooltiptext">{idTooltipText}</span>
            <div className="flex gap-5">
              <h3 className="font-bold text-lg">Event ID:</h3>
              <p className="text-lg">{EventDetailViewProps.eventID}</p>
            </div>
            <p className="text-sm opacity-70">{`Users can enter this ID on ${window.location.host} to vote. Click this box to copy the ID.`}</p>
          </div>
          <div className="flex flex-col border-2 border-offWhite rounded-lg p-3">
            <div className="flex justify-between">
              <div className="flex gap-3 items-center">
                <h3 className="font-bold text-lg">Status:</h3>
                <div className="text-sm">
                  {isOpenForVotings ? (
                    <div className=" text-good">
                      {eventStatusUpdateLoading
                        ? "Changing..."
                        : "Open for Submissions"}
                    </div>
                  ) : (
                    <div className="px-3 py-1 text-bad rounded-lg">
                      {eventStatusUpdateLoading
                        ? "Changing..."
                        : "Closed for submissions"}
                    </div>
                  )}
                </div>
              </div>
              <button
                className="text-sm px-3 py-1 rounded-lg text-darkBlue border-2 border-darkBlue hover:text-white hover:bg-darkBlue w-fit transition-all"
                onClick={() => updateEventStatusIsOpenForVotings()}
              >
                {isOpenForVotings
                  ? eventStatusUpdateLoading
                    ? "Updating..."
                    : "Close event"
                  : eventStatusUpdateLoading
                  ? "Updating..."
                  : "Open event"}
              </button>
            </div>

            <p className="text-sm opacity-70">
              When this is open, users can vote for the event.
            </p>
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex gap-4 justify-center">
              <button
                className="px-7 py-2 border-2 rounded-lg self-center hover:text-white hover:bg-darkBlue transition all"
                onClick={handlePrint}
              >
                Print event
              </button>
              <a
                className="px-7 py-2 border-2 rounded-lg hover:text-white hover:bg-darkBlue transition all"
                href={qr}
                download={`${EventDetailViewProps.name}.png`}
              >
                Download QR Code
              </a>
            </div>
            {qr && (
              <div className="flex-col flex items-center">
                <img
                  src={qr}
                  alt={`qr code for event: ${EventDetailViewProps.eventName}`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {wantsDeletion && (
        <div className="backdrop" onClick={closeDeletionConfirmationPopUp}>
          <div
            className="centered-popup bg-white fadeIn rounded-lg p-12 flex flex-col gap-10 text-center"
            onClick={handlePopUpClick}
          >
            <div className="flex flex-col">
              <p className="text-xl font-bold">
                Do you really want to delete the event: "
                <span className="text-blue">{EventDetailViewProps.name}</span>"
              </p>
              <p className="tex-sm text-darkBlue text-light opacity-50">
                This action can't be reversed. All reviews will be deleted
              </p>
            </div>
            <div className="flex flex-row gap-5">
              <button
                onClick={handleEventDeletion}
                className={`${
                  deletionLoading ? "text-white bg-red" : "text-red bg-white"
                } w-full py-2 rounded-lg font-bold border-red border-2 hover:bg-red hover:text-white transition-all`}
              >
                {deletionLoading ? "Deleting..." : "Yes, delete it!"}
              </button>
              <button
                onClick={() => setWantsDeletion(false)}
                className="text-white bg-blue w-full py-2 rounded-lg font-bold"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EventDetailView;
